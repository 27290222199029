import BusinessRegister from "../features/Business/BusinessLogin/BusinessRegister"

const BusinessRegisterPage = () => {
  return (

    <div className="login-page">
      <BusinessRegister />
    </div>

  )
}

export default BusinessRegisterPage
