import BusinessRegisterLoginPage from "../features/Business/BusinessLogin/BusinessRegisterLoginPage";

const BusinessLoginPage = () => {
  return (
      <div className="login-page">
          <BusinessRegisterLoginPage />
      </div>
  );
};

export default BusinessLoginPage;