import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { SearchIcon, UserPlusIcon } from "lucide-react";
import endpoint from "../../../../api/endpoints";
import { useDrawer } from "../../BusinessDrawerContext";
import {
  addTime,
  getCurrentTime12HrFormat,
  getMonthAndDayNames,
  getTimeIntervals,
  range,
} from "../utils";

// Assuming you have a ServiceSelector component
import ServiceSelector from "../../../../components/ServiceSelector";

const NewAppointmentDrawer = ({ payload }) => {
  const { closeDrawer } = useDrawer();
  const { date, employee, service, status, price, start } = payload;
  const [selectedTeamMember, setSelectedTeamMember] = useState(employee);
  const [occuranceState, setOccuranceState] = useState("Doesn't repeat");
  const [startTime, setStartTime] = useState(start);
  const [selectedDate, setSelectedDate] = useState(date);
  const [timeInterval, setTimeInterval] = useState(getTimeIntervals(start));
  const [categories, setCategories] = useState([]);
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  const userDetails = useSelector((state: any) => state?.currentUserDetails);
  const establishmentId = userDetails?.establishmentId || "";

  useEffect(() => {
    const getEstablishmentDetails = async () => {
      try {
        const establishmentData = await endpoint.getEstablishmentDetailsById(establishmentId);
        if (establishmentData?.data?.success) {
          setCategories(establishmentData?.data?.data?.categories || []);
        }
      } catch (error) {
        console.error("Error fetching establishment details:", error);
      }
    };

    const getClientDetails = async () => {
      try {
        const clientDetails = await endpoint.getClientsList(establishmentId);
        if (clientDetails?.data?.success) {
          setClients(clientDetails?.data?.data?.content);
        }
      } catch (error) {
        console.error("Error fetching client details:", error);
      }
    };

    getEstablishmentDetails();
    getClientDetails();
  }, [establishmentId]);

  useEffect(() => {
    const result = clients?.map(({ customerId, fullName, emailAddress, phoneNumber }) => ({
      customerId,
      fullName,
      emailAddress,
      phoneNumber,
    }));
    setFilteredClients(result);
  }, [clients]);

  const handleFilterDrawerSubmit = async () => {
    const total = calculateTotals(selectedServices);
    const payLoad = {
      appointmentBooking: {
        establishmentId: establishmentId,
        id: "",
        createdDate: new Date(),
        createdBy: "",
        lastModifiedDate: new Date(),
        lastModifiedBy: "",
        customerId: selectedClient?.customerId,
        bookedBy: "",
        bookingTime: new Date(),
        totalDuration: total?.totalDuration,
        totalCost: total?.totalSalePrice,
        appointmentServices: [],
        paymentInfo: {
          payAtVenue: true,
          cardStoreId: "string",
          paymentStatus: "string",
          paymentTxnId: "string",
        },
      },
      currency: "USD",
      paymentMode: "ONLINE",
    };

    const appointmentServices = selectedServices?.map((item) => ({
      serviceId: item?.categories[0]?.services[0]?.serviceId,
      optionId: item?.categories[0]?.services[0]?.options[0]?.optionId,
      serviceNotes: "",
      serviceCost: item?.categories[0]?.services[0]?.options[0]?.salePrice,
      bookingStatus: "confirmed",
    }));

    payLoad.appointmentBooking.appointmentServices = appointmentServices;

    try {
      const appointmentBooking = await endpoint.saveAppointmentBookings(payLoad);
      console.log("Appointment booked:", appointmentBooking);
      closeDrawer();
    } catch (error) {
      console.error("Error booking appointment:", error);
    }
  };

  const calculateTotals = (services) => {
    let totalSalePrice = 0;
    let totalDuration = 0;

    services.forEach((serviceCategory) => {
      serviceCategory.categories.forEach((category) => {
        category.services.forEach((service) => {
          service.options.forEach((option) => {
            totalSalePrice += option.salePrice || 0;
            totalDuration += option.duration || 0;
          });
        });
      });
    });

    return { totalSalePrice, totalDuration };
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', bgcolor: '#fff' }}>
      <Box sx={{ bgcolor: '#1B1464', color: 'white', p: 2, textAlign: 'center' }}>
        <Typography variant="h6" sx={{ mb: 2 }}>{getMonthAndDayNames(selectedDate)}</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-around', gap: 2 }}>
          <TextField
            select
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            variant="outlined"
            sx={styles.select}
            SelectProps={{
              native: true,
            }}
          >
            {timeInterval.map((time) => (
              <option key={time} value={time}>{time}</option>
            ))}
          </TextField>
          <TextField
            select
            value={occuranceState}
            onChange={(e) => setOccuranceState(e.target.value)}
            variant="outlined"
            sx={styles.select}
            SelectProps={{
              native: true,
            }}
          >
            {["Doesn't repeat", "Every day", "Every week", "Every month"].map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </TextField>
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Client</Typography>
        <Autocomplete
          options={[...filteredClients, { isAddNew: true }]}
          getOptionLabel={(option) =>
            option.isAddNew ? "Add new client" : `${option.fullName}`
          }
          onChange={(event, newValue) => {
            if (!newValue?.isAddNew) {
              setSelectedClient(newValue);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Add client"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon size={20} />
                  </InputAdornment>
                ),
              }}
              sx={styles.textField}
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
                            
              {option.isAddNew ? (
<>
<Divider></Divider>
<Button
                  startIcon={<UserPlusIcon size={20} />}
                  onClick={() => console.log("Add new client clicked")}
                  sx={{ color: '#B3B3B3', justifyContent: 'center', width: '100%',padding:"30px" }}
                >
                  Add new client
                </Button>
</>
              
              ) : (
                <Box>
                  <Typography>{option.fullName}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {option.emailAddress}
                  </Typography>
                  <Divider></Divider>
                </Box>
              )}
            </li>
          )}
        />

        {selectedClient ? (
          <Card sx={{ mt: 2, bgcolor: '#E6E1FF', borderRadius: '10px', boxShadow:"none" }}>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{selectedClient.fullName}</Typography>
                  <Typography variant="body2" color="text.secondary">{selectedClient.emailAddress}</Typography>
                  {selectedClient.phoneNumber && (
                    <Typography variant="body2" color="text.secondary">{selectedClient.phoneNumber}</Typography>
                  )}
                </Box>
                <Button
                  onClick={() => setSelectedClient(null)}
                  sx={{ color: '#4D4D4D', minWidth: 'auto' }}
                >
                  X
                </Button>
              </Box>
            </CardContent>
          </Card>
        ) : (
          <Card sx={{ mt: 2, bgcolor: '#E6E1FF', borderRadius: '10px', boxShadow:"none" }}>
            <CardContent>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Walk-in</Typography>
              <Typography variant="body2" color="text.secondary">Default</Typography>
            </CardContent>
          </Card>
        )}

        <Divider sx={{ my: 2 }} />

        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>Service</Typography>
        <ServiceSelector
          selectedServices={selectedServices}
          setSelectedServices={setSelectedServices}
          categories={categories}
        />
      </Box>

      <Box sx={{ p: 2, borderTop: '1px solid #e0e0e0' }}>
        <Button
          fullWidth
          variant="contained"
          onClick={handleFilterDrawerSubmit}
          sx={styles.btn}
        >
          Done
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  btn: {
    backgroundColor: "#825FFF",
    color: "#FFFFFF",
    fontWeight: 600,
    fontSize: "16px",
    padding: "10px",
    borderRadius: "10px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#5A3EBF",
    },
  },
  textField: {
    "& .MuiInputBase-root": {
      height: "48px",
      borderRadius: "9px",
    },
  },
  select: {
    width: "45%",
    bgcolor: 'white',
    borderRadius: '9px',
    "& .MuiInputBase-root": {
      height: "48px",
    },
    "& .MuiSelect-select": {
      paddingTop: '12px',
      paddingBottom: '12px',
    },
  },
};

export default NewAppointmentDrawer;