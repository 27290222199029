import React, { Fragment, useState } from "react";
import { Grid, Box } from "@mui/material";
import Text from "../../components/Text";
import CloseIcon from "@mui/icons-material/Close";

import { updateSearchSelectedBox } from "../../store/slices/searchPageSlice.js";
import LocationPanel from "./LocationPanel.tsx";

import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { MdMyLocation } from "react-icons/md";
import { CiTimer } from "react-icons/ci";
import { updateSearchLocationList } from "../../store/slices/searchPageSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  GoogleMap,
  InfoWindowF,
  Marker,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";
import endpoint from "../../api/endpoints";
import VenuePanel from "./SelectLocation/VenuePanel.js";

const SelectTreatment = ({ props }) => {
  const dispatch = useDispatch();
  const closeFilterPannel = () => {
    dispatch(updateSearchSelectedBox({ selectedBox: "" }));
  };

  const [inputLocation, setInputLocation] = useState("");

  const navigate = useNavigate();

  // const [center, setCenter] = useState(null);
  const [suggestedLocations, setSuggestedLocations] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);

  const [map, setMap] = useState(null);

  const [activeMarker, setActiveMarker] = useState(null);
  const [activeCurrentLocation, setActiveCurrentLocation] = useState(false);
  const [center, setCenter] = useState(null);
  const [locationName, setLocationName] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [mapHeight, setMapHeight] = useState("400px");
  const [treatmentServicesList, setTreatmentServicesList] = useState([]);

  // const markers = [...treatmentServicesList];

  const transformData = (data) => ({
    position: { lat: data.geoX || 0, lng: data.geoY || 0 },
    name: data.establishmentName,
    id: data.establishmentId,
  });

  // const transformedData = markers.map(transformData);

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const range = 5;
          setCenter({ lat: latitude, lng: longitude });

          const geocodeResponse = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
          );
          const geocodeData = await geocodeResponse.json();

          if (geocodeData.results && geocodeData.results.length > 0) {
            const location = `${geocodeData.results[0].formatted_address.slice(
              0,
              20
            )}...`;

            dispatch(
              updateSearchLocationList([
                {
                  location: location,
                  center: { lat: latitude, lng: longitude },
                  range: range,
                },
              ])
            );
            setRecentSearches((prev) => [
              {
                location: location,
                region:
                  geocodeData.results[0].address_components.find((c) =>
                    c.types.includes("administrative_area_level_1")
                  )?.short_name || "Unknown Region",
              },
              ...prev,
            ]);
            // onHide();
          }
        },

        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.error(
                "Error getting geolocation: User denied Geolocation"
              );
              alert(
                "Location access is required for this feature. Please enable it in your browser settings."
              );
              break;
            case error.POSITION_UNAVAILABLE:
              console.error(
                "Error getting geolocation: Location information is unavailable"
              );
              alert(
                "Location information is currently unavailable. Please try again later."
              );
              break;
            case error.TIMEOUT:
              console.error(
                "Error getting geolocation: The request to get user location timed out"
              );
              alert(
                "The request to get your location timed out. Please try again."
              );
              break;
            default:
              console.error(
                "Error getting geolocation: An unknown error occurred"
              );
              alert(
                "An unknown error occurred while trying to access your location. Please try again."
              );
              break;
          }
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places`;
    script.defer = true;
    script.async = true;

    script.onload = initializeGoogleMaps;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [locationName]);

  const initializeGoogleMaps = () => {};

  const searchByLocation = async (e) => {
    const { value } = e.target;

    if (value.length >= 3 && window.google) {
      setInputLocation(value);
      const autocompleteService =
        new window.google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        { input: value },
        (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            setSuggestedLocations(predictions);
          }
        }
      );
    } else {
      setSuggestedLocations([]);
    }
  };

  const handleSuggestionClick = async (placeId) => {
    const placeService = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    placeService.getDetails({ placeId }, (place, status) => {
      if (
        status === window.google.maps.places.PlacesServiceStatus.OK &&
        place
      ) {
        const location = `${place.formatted_address.slice(0, 40)}...`;
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        dispatch(
          updateSearchLocationList([
            {
              location: location,
              center: { lat, lng },
              range: 5,
            },
          ])
        );

        setRecentSearches((prev) => [
          {
            location,
            region:
              place.address_components.find((c) =>
                c.types.includes("administrative_area_level_1")
              )?.short_name || "Unknown Region",
          },
          ...prev,
        ]);

        setSuggestedLocations([]);
        // props.onHide();
      }
    });
  };

  const { isLoaded: apiIsLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setCenter({ lat: latitude, lng: longitude });

          const geocodeResponse = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
          );
          const geocodeData = await geocodeResponse.json();

          if (geocodeData.results && geocodeData.results.length > 0) {
            const location = geocodeData.results[0].formatted_address;
            setLocationName(location);
          }

          setIsLoaded(true);
        },
        (error) => {
          console.error("Error getting geolocation", error);
          setIsLoaded(true);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setIsLoaded(true);
    }
  }, []);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
    setActiveCurrentLocation(false);
  };

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    function updateMapHeight() {
      if (window.innerWidth <= 768) {
        setMapHeight("250px");
      } else {
        setMapHeight("400px");
      }
    }

    updateMapHeight();

    window.addEventListener("resize", updateMapHeight);

    // Cleanup
    return () => {
      window.removeEventListener("resize", updateMapHeight);
    };
  }, []);

  const containerStyle = {
    width: "100%",
    height: mapHeight,
    borderRadius: "10px",
  };

  const handleCurrentLocationClick = () => {
    setActiveMarker(null);
    setActiveCurrentLocation(true);
  };

  return (
    <div className="treatment-container">
      {/* <LocationPanel />
      <Box className="home-treatments-filter">
        <div className="flex-between-container">
          <Text sx={styles.header} align="left" name="Venues" />
          <CloseIcon onClick={() => closeFilterPannel()} />
        </div>
        <VenuePanel />
      </Box> */}

      <div className="px-3">
        <input
          type="search"
          placeholder="Search for your location/society/locality"
          className="px-3 py-3 w-full  border rounded-md mb-3 "
          onChange={searchByLocation}
        />
      </div>
      <div
        className="flex items-center cursor-pointer px-3"
        style={{ color: "blueviolet" }}
        onClick={() => getCurrentLocation()}
      >
        <MdMyLocation size={18} className="mr-2" />
        <span className="text-base font-semibold">Use current location</span>
      </div>

      <div className="mt-6 shadow-lg px-3 py-3">
        {suggestedLocations && suggestedLocations.length > 0 ? (
          <>
            {suggestedLocations.map((item, index) => (
              <div
                className="cursor-pointer"
                key={index}
                onClick={() => handleSuggestionClick(item.place_id)}
              >
                <p>{item.description}</p>
              </div>
            ))}
          </>
        ) : (
          <>
            <h4 className="mt-2">Recents</h4>
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
              {recentSearches.length > 0 ? (
                recentSearches.map((search, index) => (
                  <div
                    key={index}
                    className="text-gray-600 flex items-center mt-3"
                  >
                    <CiTimer size={23} />
                    <div className="ml-6">
                      <h5 className="text-base font-bold">{search.location}</h5>
                      <h5 className="text-sm font-medium">{search.region}</h5>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-gray-600">No recent searches</p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SelectTreatment;
const styles = {
  header: {
    fontSize: "18px",
    fontWeight: 700,
    color: "#4D4D4D",
  },
};
