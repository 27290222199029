import BusinessForgotPassword from '../features/Business/BusinessLogin/BusinessForgotPassword'

const BusinessForgotPasswordPage = () => {
  return (
    <div className="login-page">
    <BusinessForgotPassword />
</div>
  )
}

export default BusinessForgotPasswordPage
